const { getUserDetails } = require('@/pages/api/api')
const { default: axios } = require('axios')
const { getAuthKey, getSelectedTenantDetails } = require('constant/functions')

module.exports = {
  user_details: async (onSuccess, onError) => {
    await getUserDetails(getAuthKey()).then((res) => onSuccess(res?.user))
  },
  tenant_details: async (onSuccess, onError) => {
    axios.get('/api/user_tenants_details', { params: { auth_key: getAuthKey() } }).then((res) => {
      onSuccess(getSelectedTenantDetails(res.data.data.tenants))
    })
  },
}
