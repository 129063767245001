import axios from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles

// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper/modules'
import { BigText } from '@/components/home/common'
import { Widont } from '@/components/Widont'
import { getUserDetails } from '@/pages/api/api'
import { getAuthKey, getSelectedTenantDetails, getThumbnailImage } from 'constant/functions'
import {
  MixpanelEventName,
  MixpanelPropertiesName,
  MixpanelPropertiesValues,
  MixpanelTracking,
} from 'constant/mixpnael'

const TemplatesSlider = ({ templates }) => {
  const router = useRouter()

  const [userDetails, setUserDetails] = useState(null)
  const [tenantDetails, setTenantDetails] = useState(null)

  // user Details and tenant details fetching
  useEffect(() => {
    if (getAuthKey() !== undefined) {
      const auth_key = getAuthKey()
      if (auth_key) {
        getUserDetails(auth_key).then((res) => {
          if (res?.user) {
            setUserDetails(res.user)
          }
        })

        axios
          .get('/api/user_tenants_details', { params: { auth_key: getAuthKey() } })
          .then((res) => {
            setTenantDetails(getSelectedTenantDetails(res.data.data.tenants))
          })
          .catch((err) => {
            if (err?.response?.data?.error?.message == 'unauthorized') {
              window.location.reload()
            } else {
              err?.response?.data?.error?.message && alert(err?.response?.data?.error?.message)
            }
          })
      }
    }
  }, [router])

  return (
    <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 md:px-8 ">
      <div className="mb-5 flex items-center justify-center">
        <BigText>
          <Widont>Select a Template and Get started.</Widont>
        </BigText>
      </div>
      <Swiper
        slidesPerView="auto"
        slidesPerGroup={1}
        spaceBetween={16}
        loop={false}
        navigation={true}
        className="md:px-[50px]"
        modules={[Navigation, Pagination]}
      >
        {templates?.map((template) => {
          return (
            <SwiperSlide
              className="border     rounded-md border-[#eaeaea]"
              style={{
                borderRadius: ' 10px',
                width: '300px',
                minHeight: '100px',
                padding: '24px',
              }}
              onClick={() => {
                router.push(`/templates/${template.slug}`),
                  MixpanelTracking(MixpanelEventName.homepage_template, {
                    [MixpanelPropertiesName.user_name]: userDetails?.name,
                    [MixpanelPropertiesName.user_email]: userDetails?.email,
                    [MixpanelPropertiesName.tenant_name]: tenantDetails?.id,
                    [MixpanelPropertiesName.homepage_template_property]: template.title,
                  })
              }}
            >
              <div className="   block relative  overflow-hidden cursor-pointer">
                <article
                  className="flex flex-col h-full  overflow-hidden    "
                  data-aos="zoom-y-out"
                >
                  <header>
                    <div className=" max-w-[300px] min-w-max  h-[200px]  relative mb-6 overflow-hidden  rounded-sm">
                      <img
                        className=" w-full h-full object-cover object-top"
                        src={template.images[0]}
                        alt=""
                      />
                    </div>

                    <h3 className=" px-2 text-xl font-bold leading-snug tracking-tight mb-2 text-center">
                      {template.title.length > 20
                        ? template.title.substr(0, 20) + '..'
                        : template.title}
                    </h3>
                  </header>
                </article>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="flex items-center justify-center mt-6">
        <a
          className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
          href={`/templates`}
        >
          View All Templates
        </a>
      </div>
    </div>
  )
}

export default TemplatesSlider
