import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { check_login } from 'constant/check_auth'
import { get_configs } from '@/pages/api/api'
import { getAuthKey } from 'constant/functions'
import { site_url } from 'constant/url'
import User_store from '@/store/User_store'
import { cn } from '@/utils/classNames'

const Onboarding_steps_bar = ({ component_styles }) => {
  const [isRegister, setIsRegister] = useState(false)
  const [isTemplate, setIsTemplate] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [isCustomized, setIsCustomized] = useState(false)
  const [isIntegrationAvailable, setIsIntegrationAvailable] = useState(false)
  const [isCustomDomainAvailable, setISCustomDomainAvailable] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const router = useRouter()

  const [steps_bar, setSteps_bar] = useState([
    {
      id: 1,
      name: 'Register',
      status: isRegister,
      path: `/signin?to=${site_url}${router.asPath}`,
    },
    { id: 2, name: 'Select Template', status: isTemplate, path: '/workspace/templates' },
    { id: 3, name: 'Customize', status: isCustomized, path: '/editor' },
    {
      id: 4,
      name: 'Add Integration',
      status: isIntegrationAvailable,
      path: '/workspace/integrations',
    },
    { id: 5, name: 'Add Domain', status: isIntegrationAvailable, path: '/workspace/domain' },
  ])

  // fetching
  useEffect(() => {
    setInterval(() => {
      setIsFetching(false)
    }, 3000)
  }, [router])

  // Data fetching
  useEffect(() => {
    if (check_login(router, false)) {
      setIsRegister(true)
    }
    if (getAuthKey() !== undefined) {
      get_configs(getAuthKey(), 'general,onboarding').then((res) => {
        if (res.theme) {
          setIsTemplate(true), setSelectedTemplate(res.theme)
        }
        if (res.app_color_primary) {
          setIsCustomized(true)
        }
      })
      get_configs(getAuthKey(), 'integrations').then((response) => {
        if (Object.keys(response).length > 0) {
          setIsIntegrationAvailable(true)

          //
          User_store.tenant_details((resp) => {
            if (resp?.domain && !resp?.domain.includes('tradly.co')) {
              setISCustomDomainAvailable(true)
            }
          })
        }
      })
    }
  }, [router])

  //open_link
  const open_link = (step) => {
    switch (step.id) {
      case 1:
        if (!isRegister) {
          router.push(step.path)
        } else {
          alert('You already have registered.')
        }
        break
      case 2:
        if (isRegister) {
          if (!isTemplate) {
            router.push(step.path)
          } else {
            alert('You selected a template before.')
          }
        } else {
          if (router.pathname === '/templates') {
            alert('Please sign in or signup first.')
          } else {
            router.push(step.path)
          }
        }

        break
      case 3:
        if (isRegister) {
          router.push(step.path)
        } else {
          alert('Please sign in or signup first.')
        }
        break
      case 4:
        if (isRegister) {
          router.push(step.path)
        } else {
          alert('Please sign in or signup first.')
        }
        break
      case 5:
        if (isRegister) {
          router.push(step.path)
        } else {
          alert('Please sign in or signup first.')
        }
        break

      default:
        break
    }
  }

  return (
    (isRegister === false ||
      isTemplate === false ||
      isCustomized === false ||
      isIntegrationAvailable === false ||
      isCustomDomainAvailable === false) &&
    !isFetching && (
      <div className={cn(' w-full mx-auto my-4  pb-10 ', component_styles)}>
        {/* Bar section */}
        <div className="flex pb-3">
          <div className="flex-1"></div>

          {/* register */}
          <div className="flex-1">
            <div
              className={[
                'w-10 h-10  mx-auto rounded-full text-lg text-white flex items-center',
                isRegister ? 'bg-primary' : 'bg-white border-2 border-grey-200',
              ].join(' ')}
            >
              {isRegister ? (
                <span className="text-white text-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 mx-auto my-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
              ) : (
                <span className="text-gray-500 text-center w-full">1</span>
              )}
            </div>
          </div>

          <div className="w-[10%] sm:w-[13%]   align-center items-center align-middle content-center flex">
            <div className="w-full bg-gray-300 rounded items-center align-middle align-center flex-1">
              <div
                className={[
                  ' text-xs leading-none py-1 text-center text-grey-darkest rounded ',
                  isRegister ? 'bg-primary' : 'bg-gray-300',
                ].join(' ')}
                style={{ width: '100%' }}
              ></div>
            </div>
          </div>

          {/* Select Template */}
          <div className="flex-1">
            <div
              className={[
                'w-10 h-10  mx-auto rounded-full text-lg text-white flex items-center',
                isTemplate ? 'bg-primary' : 'bg-white border-2 border-grey-200',
              ].join(' ')}
            >
              {isTemplate ? (
                <span className="text-white text-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 mx-auto my-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
              ) : (
                <span className="text-gray-500 text-center w-full">2</span>
              )}
            </div>
          </div>

          <div className="w-[10%] sm:w-[13%]   align-center items-center align-middle content-center flex">
            <div className="w-full bg-gray-300 rounded items-center align-middle align-center flex-1">
              <div
                className={[
                  ' text-xs leading-none py-1 text-center text-grey-darkest rounded ',
                  isRegister && isTemplate ? 'bg-primary' : 'bg-gray-300',
                ].join(' ')}
                style={{ width: '100%' }}
              ></div>
            </div>
          </div>

          {/* Customaization */}
          <div className="flex-1">
            <div
              className={[
                'w-10 h-10  mx-auto rounded-full text-lg text-white flex items-center',
                isCustomized ? 'bg-primary' : 'bg-white border-2 border-grey-200',
              ].join(' ')}
            >
              {isCustomized ? (
                <span className="text-white text-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 mx-auto my-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
              ) : (
                <span className="text-gray-500 text-center w-full">3</span>
              )}
            </div>
          </div>

          <div className="w-[10%] sm:w-[13%]   align-center items-center align-middle content-center flex">
            <div className="w-full bg-gray-300 rounded items-center align-middle align-center flex-1">
              <div
                className={[
                  ' text-xs leading-none py-1 text-center text-grey-darkest rounded ',
                  isRegister && isTemplate && isCustomized ? 'bg-primary' : 'bg-gray-300',
                ].join(' ')}
                style={{ width: '100%' }}
              ></div>
            </div>
          </div>

          {/* Add integrations */}
          <div className="flex-1">
            <div
              className={[
                'w-10 h-10  mx-auto rounded-full text-lg text-white flex items-center',
                isIntegrationAvailable ? 'bg-primary' : 'bg-white border-2 border-grey-200',
              ].join(' ')}
            >
              {isIntegrationAvailable ? (
                <span className="text-white text-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 mx-auto my-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
              ) : (
                <span className="text-gray-500 text-center w-full">4</span>
              )}
            </div>
          </div>

          <div className="w-[10%] sm:w-[13%]   align-center items-center align-middle content-center flex">
            <div className="w-full bg-gray-300 rounded items-center align-middle align-center flex-1">
              <div
                className={[
                  ' text-xs leading-none py-1 text-center text-grey-darkest rounded ',
                  isRegister && isTemplate && isCustomized && isIntegrationAvailable
                    ? 'bg-primary'
                    : 'bg-gray-300',
                ].join(' ')}
                style={{ width: '100%' }}
              ></div>
            </div>
          </div>
          {/* Add Domain */}
          <div className="flex-1">
            <div
              className={[
                'w-10 h-10  mx-auto rounded-full text-lg text-white flex items-center',
                isCustomDomainAvailable ? 'bg-primary' : 'bg-white border-2 border-grey-200',
              ].join(' ')}
            >
              {isCustomDomainAvailable ? (
                <span className="text-white text-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 mx-auto my-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
              ) : (
                <span className="text-gray-500 text-center w-full">5</span>
              )}
            </div>
          </div>

          <div className="flex-1"></div>
        </div>

        {/* Name Section */}
        <div className="flex text-xs content-center text-center">
          {steps_bar.map((step) => {
            return (
              <div className="w-1/5">
                <button
                  className={['hover:text-primary'].join(' ')}
                  onClick={() => open_link(step)}
                >
                  {step.name}
                </button>
              </div>
            )
          })}
        </div>
      </div>
    )
  )
}

export default Onboarding_steps_bar
